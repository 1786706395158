(() => {
	$('.logo-video-slider').each(function (i, el) {
		let swiperClass = 'logo-video-slider-' + i;
		$(this).addClass(swiperClass);

		// navigation
		let prevClass = 'logo-video-slider-button-prev-' + i;
		let nextClass = 'logo-video-slider-button-next-' + i;
		$(this).parent().find('.swiper-button-prev').addClass(prevClass);
		$(this).parent().find('.swiper-button-next').addClass(nextClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 16,
			centerInsufficientSlides: true,
			navigation: {
				prevEl: '.' + prevClass,
				nextEl: '.' + nextClass
			},
			breakpoints: {
				768: {
					spaceBetween: 20
				}
			}
		});
	});
})();
