// Function to set data-height CSS property
function updateHeight() {
	const elements = document.querySelectorAll('[data-height]');

	elements.forEach(element => {
		// Get the height of the element
		const height = element.offsetHeight;

		// Subtract 2px from the height
		const adjustedHeight = height - 2;

		// Set the custom CSS property --data-height with the element's height
		element.style.setProperty('--data-height', `${adjustedHeight}px`);
	});
}
setTimeout(() => {
	updateHeight();
}, 1000);

// Update height on window resize or orientation change
window.addEventListener('resize', updateHeight);
window.addEventListener('orientationchange', updateHeight);
