document.addEventListener('DOMContentLoaded', function () {
	// Check if the element with the class `.event-card-parallax` is present
	if (document.querySelector('.event-card-parallax')) {
		// Register ScrollTrigger plugin
		gsap.registerPlugin(ScrollTrigger);

		// Create a GSAP timeline
		gsap.from('.circle-sun-down', {
			ease: 'power1.inOut',
			opacity: 1,
			scrollTrigger: {
				trigger: '.event-card-parallax',
				scrub: 1,
				start: '40%',
				end: '50%'
			}
		});

		// Add animation to the timeline
		gsap.to('.circle-sun-down', {
			opacity: 0,
			scrollTrigger: {
				trigger: '.event-card-parallax',
				scrub: 1,
				start: '40%',
				end: '50%'
			},
			display: 'none'
		});

		gsap.from('.circle-night', {
			ease: 'power1.inOut',
			opacity: 0,
			scrollTrigger: {
				trigger: '.event-card-parallax',
				scrub: 1,
				start: '40%',
				end: '50%'
			}
		});

		// Add animation to the timeline
		gsap.to('.circle-night', {
			opacity: 1,
			scrollTrigger: {
				trigger: '.event-card-parallax',
				scrub: 1,
				start: '40%',
				end: '50%'
			}
		});
	}
});
