(() => {
	// Select all sections and filter out ones with empty or blank id attributes
	const sections = Array.from(document.querySelectorAll('.section')).filter(
		section => section.id.trim() !== ''
	);

	const navLinks = document.querySelectorAll('.scroll-navigation-section a');
	const scrollSpySection = document.querySelector(
		'.scroll-navigation-section'
	);

	if (scrollSpySection) {
		const updateActiveLink = () => {
			let currentSection = null;

			sections.forEach(section => {
				const rect = section.getBoundingClientRect();
				if (
					rect.top <= window.innerHeight / 2 &&
					rect.bottom >= window.innerHeight / 2
				) {
					currentSection = section;
				}
			});

			navLinks.forEach(navLink => {
				navLink.classList.remove('js-active');
			});

			if (currentSection) {
				const id = currentSection.getAttribute('id');
				const activeNavLink = document.querySelector(
					`.scroll-navigation-section a[href="#${id}"]`
				);
				if (activeNavLink) {
					activeNavLink.classList.add('js-active');
				}
			}
		};

		const updateStickyClass = () => {
			const rect = scrollSpySection.getBoundingClientRect();
			if (document.querySelector('.site-header.js-user-scroll-up')) {
				if (rect.top <= headerHeight) {
					scrollSpySection.classList.add('js-sticky');
				} else {
					scrollSpySection.classList.remove('js-sticky');
				}
			} else {
				if (rect.top <= 0) {
					scrollSpySection.classList.add('js-sticky');
				} else {
					scrollSpySection.classList.remove('js-sticky');
				}
			}
		};

		window.addEventListener('scroll', () => {
			updateActiveLink();
			updateStickyClass();
		});
		window.addEventListener('resize', () => {
			updateActiveLink();
			updateStickyClass();
		});

		// Initial check to set the active link and sticky class when the script loads
		updateActiveLink();
		updateStickyClass();
	}
})();
