(() => {
	$('.coverflow-card-slider').each(function (i, el) {
		let swiperClass = 'coverflow-card-slider-' + i;
		$(this).addClass(swiperClass);

		// navigation
		let prevClass = 'coverflow-card-slider-prev-' + i;
		let nextClass = 'coverflow-card-slider-next-' + i;
		$(this).parent().find('.swiper-button-prev').addClass(prevClass);
		$(this).parent().find('.swiper-button-next').addClass(nextClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 0,
			effect: 'coverflow',
			coverflowEffect: {
				rotate: 0,
				stretch: 0,
				depth: 400,
				modifier: 1,
				slideShadows: true
			},
			centeredSlides: true,
			centerInsufficientSlides: true,
			navigation: {
				prevEl: '.' + prevClass,
				nextEl: '.' + nextClass
			}
		});
	});
})();
