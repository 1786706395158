function addLoaderToButton(buttonElement) {
	if (buttonElement) {
		let loader = buttonElement.querySelector('.loader');
		let playIcon = buttonElement.querySelector('.i-play');

		loader.style.display = 'block';
		playIcon.style.display = 'none';
	}
}

function removeLoaderToButton(buttonElement) {
	if (buttonElement) {
		let loader = buttonElement.querySelector('.loader');
		let playIcon = buttonElement.querySelector('.i-play');

		loader.style.display = 'none';
		playIcon.style.display = 'block';
	}
}

window.addLoaderToButton = addLoaderToButton;
window.removeLoaderToButton = removeLoaderToButton;
