(() => {
	(() => {
		let paged = 1;
		const nonce = localize_data.nonce;
		const ajaxUrl = localize_data.ajax_url;

		const $listingSection = $('#whatson-listing-section');
		const $clearFilters = $listingSection.find('.clear-listing-filters');

		const $whatsOnListingSection = $('.whats-on-listing-section');
		const $filterBlock = $whatsOnListingSection.find('.filters-block');

		const $listingFilter = $filterBlock.find('.listing-filter');
		const $dateFilter = $('.archive-block').find('.date-filter');
		const $appliedFiltersContainer = $whatsOnListingSection.find(
			'.applied-filters-container'
		);
		const $loadMoreButton = $whatsOnListingSection.find('.load-more');
		const $listings = $whatsOnListingSection.find('.listings');
		const $topLevelLoadingContainer = $whatsOnListingSection.find(
			'.js-listing-container-loader'
		);
		const $showMoreLoadingContainer = $whatsOnListingSection.find(
			'.js-load-more-container-loader'
		);
		const $errorContainer = $whatsOnListingSection.find(
			'.listing-error-container'
		);

		function addAOSEffects() {
			window.inViewPlugin();
		}

		function toggleListingLoader(showLoader) {
			if (showLoader) {
				$topLevelLoadingContainer.removeClass('hidden');
				$listings.html('');
				$loadMoreButton.hide();
			} else {
				$topLevelLoadingContainer.addClass('hidden');
				$loadMoreButton.show();
			}
		}

		function toggleShowMoreLoader(showLoader, showLoadMoreButton) {
			if (showLoader) {
				$loadMoreButton.hide();
				$showMoreLoadingContainer.removeClass('hidden');
			} else {
				$showMoreLoadingContainer.addClass('hidden');
				if (showLoadMoreButton) {
					$loadMoreButton.show();
				}
			}
		}

		// Handle filter selection
		$listingFilter.on('click', function () {
			$listingSection[0].scrollIntoView({
				behavior: 'instant',
				block: 'start'
			});

			const $this = $(this);

			if ($this.hasClass('active')) {
				return false;
			}

			$errorContainer.addClass('hidden');
			$this.siblings().removeClass('active');
			$this.addClass('active');

			const filters = getAppliedFilters();
			const dateYear = $('.date-filter.active').data('filter-year');
			const dateMonth = $('.date-filter.active').data('filter-month');

			paged = 1; // Reset to the first page
			toggleListingLoader(true);

			loadCards(
				paged,
				filters,
				dateYear,
				dateMonth,
				function handleFilterAPICall(error, response) {
					toggleListingLoader(false);

					if (error) {
						// display the error message on the UI
						$appliedFiltersContainer.addClass('hidden');

						$listings.html('');
						$loadMoreButton.hide();

						$errorContainer
							.find('.error-message-primary-text')
							.text(error.message);
						$errorContainer.removeClass('hidden');

						return;
					}

					$listings.html(
						response && response.data && response.data.html
					);

					let total_records_text;

					if (response.data.total_records > 1) {
						total_records_text = `${response.data.total_records} articles ${localize_data.text_results_found}`;
					} else {
						total_records_text = `${response.data.total_records} article ${localize_data.text_result_found}`;
					}

					$('.listing-results-count').text(total_records_text);

					var isMobileView = window.innerWidth < 768;

					if (Object.keys(filters).length) {
						//new filter was applied
						if (!isMobileView) {
							$appliedFiltersContainer.removeClass('hidden');
						}
					} else {
						//clear filters
						$appliedFiltersContainer.addClass('hidden');
					}
				}
			);
		});

		// Handle date filter selection
		$dateFilter.on('click', function () {
			$listingSection[0].scrollIntoView({
				behavior: 'instant',
				block: 'start'
			});

			const $this = $(this);

			if ($this.hasClass('active')) {
				return false;
			}

			$errorContainer.addClass('hidden');
			$dateFilter.removeClass('active');
			$this.addClass('active');

			const dateYear = $this.data('filter-year');
			const dateMonth = $this.data('filter-month');
			const filters = getAppliedFilters();

			paged = 1; // Reset to the first page
			toggleListingLoader(true);

			loadCards(
				paged,
				filters,
				dateYear,
				dateMonth,
				function handleFilterAPICall(error, response) {
					toggleListingLoader(false);

					if (error) {
						// display the error message on the UI
						$appliedFiltersContainer.addClass('hidden');

						$listings.html('');
						$loadMoreButton.hide();

						$errorContainer
							.find('.error-message-primary-text')
							.text(error.message);
						$errorContainer.removeClass('hidden');

						return;
					}

					$listings.html(
						response && response.data && response.data.html
					);

					let total_records_text;

					if (response.data.total_records > 1) {
						total_records_text = `${response.data.total_records} articles ${localize_data.text_results_found}`;
					} else {
						total_records_text = `${response.data.total_records} article ${localize_data.text_result_found}`;
					}

					$('.listing-results-count').text(total_records_text);

					$appliedFiltersContainer.removeClass('hidden');
				}
			);
		});

		// Handle clear filters
		$clearFilters.on('click', function (event) {
			event.preventDefault();
			event.stopPropagation();

			$listingFilter.removeClass('active');
			$dateFilter.removeClass('active');

			$('.listing-filter[data-filter-value="*"]').trigger('click');
		});

		function getAppliedFilters() {
			const filters = {};

			$('.filter-group').each(function () {
				const key = $(this).data('filter-key');
				const activeFilter = $(this).find('li.active');
				const filterValue = activeFilter.data('filter-value');

				if (filterValue && filterValue !== '*') {
					filters[key] = filterValue;
				}
			});

			return filters;
		}

		$loadMoreButton.on('click', function (event) {
			event.preventDefault();
			event.stopPropagation();

			const filters = getAppliedFilters();
			const dateYear = $('.date-filter.active').data('filter-year');
			const dateMonth = $('.date-filter.active').data('filter-month');

			toggleShowMoreLoader(true, false);

			loadCards(
				paged + 1,
				filters,
				dateYear,
				dateMonth,
				function handleLoadMoreAPICall(error, response) {
					if (error) {
						$errorContainer
							.removeClass('hidden')
							.find('.error-message')
							.text(error);
						return;
					}
					$listings.append(response.data.html);
					toggleShowMoreLoader(
						false,
						response.data.has_more_pages > 0
					);
				}
			);
		});

		function loadCards(page, filters, dateYear, dateMonth, callback) {
			paged = page;

			$.ajax({
				url: ajaxUrl,
				type: 'POST',
				data: {
					action: 'whatson_listing_filter',
					paged: paged,
					pageSize: 8,
					filters: filters,
					dateYear: dateYear,
					dateMonth: dateMonth,
					nonce: nonce
				},
				success: function (response) {
					callback(null, response);

					if (!response.data.has_more_pages) {
						$loadMoreButton.hide();
					} else {
						$loadMoreButton.show();
					}
					addAOSEffects();
				},
				error: function (jqXHR, textStatus, errorThrown) {
					if (jqXHR.responseJSON && jqXHR.responseJSON.data) {
						callback(jqXHR.responseJSON.data);
					} else {
						callback(errorThrown);
					}
				}
			});
		}
	})();

	// Accordions
	const verticalAccordion = function verticalAccordion(section) {
		const accordions = section.querySelectorAll('.accordion');
		const accHeadings = section.querySelectorAll('.accordion-heading');
		const accContents = section.querySelectorAll('.accordion-content');

		// Adjust the height of the active accordion content after resize
		const activeAccordions = section.querySelectorAll(
			'.accordion.js-active'
		);
		activeAccordions.forEach(el => {
			const accContent = el.querySelector('.accordion-content');
			accContent.style.maxHeight = accContent.scrollHeight + 'px';
		});

		const activateAccordion = index => {
			accordions.forEach((accordion, i) => {
				const accContent =
					accordion.querySelector('.accordion-content');
				if (i === index) {
					if (accordion.classList.contains('js-active')) {
						accordion.classList.remove('js-active');
						accContent.style.maxHeight = null;
					} else {
						accordion.classList.add('js-active');
						accContent.style.maxHeight =
							accContent.scrollHeight + 'px';
					}
				} else {
					accordion.classList.remove('js-active');
					accContent.style.maxHeight = null;
				}
			});
		};

		// Loop over headings and add a click event listener
		accHeadings.forEach((accHeading, index) => {
			accHeading.addEventListener('click', () => {
				activateAccordion(index);
			});
		});

		// Set the first accordion and image to active by default
		if (accordions.length > 0) {
			activateAccordion(0);
		}
	};

	const initVerticalAccordions = () => {
		const sections = document.querySelectorAll('.whats-on-listing-section');
		sections.forEach(section => verticalAccordion(section));
	};

	initVerticalAccordions();
	window.addEventListener('resize', debounce(initVerticalAccordions, 200));
	window.addEventListener(
		'orientationchange',
		debounce(initVerticalAccordions, 200)
	);
})();
