(() => {
	let paged = 1;
	const $eatDrinkListingContainer = $('#eat-drink-listing-section');
	const $filterBlock = $eatDrinkListingContainer.find('.filters-block');
	const cpt = $filterBlock.data('cpt');
	const nonce = localize_data.nonce;
	// const ajaxUrl = $filterBlock.data('ajax-url');

	const ajaxUrl = localize_data.ajax_url;
	// const resultsFoundText = results_found;

	const $listingFilter = $filterBlock.find('.js-eat-drink-listing-filter');
	const $appliedFiltersContainer = $filterBlock.find(
		'.applied-filters-container'
	);

	const $loadMoreButton = $eatDrinkListingContainer.find('.load-more');
	const $listings = $eatDrinkListingContainer.find('.listings');

	const $topLevelLoadingContainer = $eatDrinkListingContainer.find(
		'.js-listing-container-loader'
	);
	const $showMoreLoadingContainer = $eatDrinkListingContainer.find(
		'.js-load-more-container-loader'
	);
	const $errorContainer = $eatDrinkListingContainer.find(
		'.listing-error-container'
	);
	const $filterCta = $eatDrinkListingContainer.find(
		'.filter-dropdown-toggle'
	);
	const $filterModalOverlay = $eatDrinkListingContainer.find(
		'.filters-modal-overlay'
	);
	const $filterModal = $eatDrinkListingContainer.find(
		'.filters-mobile-modal'
	);

	function addAOSEffects() {
		window.inViewPlugin();
	}

	function applySliderAnimations() {
		$('.eat-drink-listing-slider').each(function (i, el) {
			let swiperClass = 'eat-drink-listing-slider-' + i;
			$(this).addClass(swiperClass);

			if ($(this).find('.swiper-slide').length > 1) {
				// navigation
				let prevClass = 'eat-drink-listing-button-prev-' + i;
				let nextClass = 'eat-drink-listing-button-next-' + i;
				$(this)
					.parent()
					.find('.swiper-button-prev')
					.addClass(prevClass);
				$(this)
					.parent()
					.find('.swiper-button-next')
					.addClass(nextClass);

				new Swiper('.' + swiperClass, {
					slidesPerView: 1,
					loop: true,
					speed: 500,
					watchOverflow: true,
					navigation: {
						prevEl: '.' + prevClass,
						nextEl: '.' + nextClass
					}
				});
			} else {
				// when there is only one slide
				$('.' + swiperClass)
					.find('.swiper-slide')
					.addClass('swiper-slide-active');
			}
		});
	}

	function toggleListingLoader(showLoader) {
		if (showLoader) {
			$topLevelLoadingContainer.removeClass('hidden');
			$listings.html('');
			$loadMoreButton.hide();
		} else {
			$topLevelLoadingContainer.addClass('hidden');
			$loadMoreButton.show();
		}
	}

	function toggleShowMoreLoader(showLoader, showLoadMoreButton) {
		if (showLoader) {
			$loadMoreButton.hide();
			$showMoreLoadingContainer.removeClass('hidden');
		} else {
			$showMoreLoadingContainer.addClass('hidden');

			if (showLoadMoreButton) {
				$loadMoreButton.show();
			}
		}
	}

	$loadMoreButton.on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();

		const filters = getAppliedFilters();

		toggleShowMoreLoader(true, false);

		loadVenues(
			paged + 1,
			filters,
			function handleLoadMoreAPICall(error, response) {
				console.log('error', error, response);
				$listings.append(response.data.html);
				toggleShowMoreLoader(false, response.data.has_more_pages > 0);
			}
		);
	});

	function handleModalVisibility(show) {
		const $defaultBookingWidgetBackground = $(
			'#default-booking-widget-background'
		);

		if (show) {
			$filterModalOverlay.css('display', 'flex');
			$filterModal.css('display', 'block');
			$defaultBookingWidgetBackground.css('display', 'none'); // Hide background
		} else {
			$filterModalOverlay.css('display', 'none');
			$filterModal.css('display', 'none');
			$defaultBookingWidgetBackground.css('display', 'block'); // Show background
		}
	}

	// filter modal

	$filterCta.on('click', function () {
		$filterModalOverlay.css('display', 'flex');
		handleModalVisibility(true);
		$filterModal.css('display', 'block');
		lenis.stop();
		$filterModalOverlay.addClass('show');
		$filterModal.addClass('show');
	});

	$filterModalOverlay.find('.cross-icon').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		handleModalVisibility(false);
		lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	$filterModalOverlay.find('.modal-actions .btn').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		handleModalVisibility(false);
		lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	function checkScreenSize() {
		if (window.matchMedia('(min-width: 768px)').matches) {
			// Screen size is medium or larger
			$filterModal.hide();
		} else {
			// Screen size is smaller than medium
			$filterModal.show();
		}
	}

	// Check screen size on window resize
	$(window).resize(function () {
		checkScreenSize();
	});

	// handle filter selection
	$listingFilter.on('click', function () {
		const $this = $(this);

		if ($this.hasClass('active')) {
			return false;
		}

		$errorContainer.addClass('hidden');

		// Remove active class from previously active button
		// $this.siblings().removeClass('active');
		$listingFilter.removeClass('active');

		// Toggle active class on clicked button
		$this.addClass('active');

		const filter = $this.data('filter-value');
		$('.listing-filter[data-filter-value=' + filter + ']').addClass(
			'active'
		);

		// Change the fill color of the SVG icon based on active state
		const svg = $this.find('.icon-svg');

		if ($this.hasClass('active')) {
			svg.find('path').css('fill', 'white');
		} else {
			svg.find('path').css('fill', 'black');
		}

		const filters = getAppliedFilters();

		paged = 1; // Reset to the first page
		toggleListingLoader(true);

		loadVenues(
			paged,
			filters,
			function handleFilterAPICall(error, response) {
				toggleListingLoader(false);

				if (error) {
					// display the error message on the UI
					$appliedFiltersContainer.addClass('hidden');

					$listings.html('');
					$loadMoreButton.hide();

					$errorContainer.find('.error-message').text(error);
					$errorContainer.removeClass('hidden');

					return;
				}

				$listings.html(response?.data?.html);

				let total_records_text;

				if (response.data.total_records > 1) {
					total_records_text = `${response.data.total_records} ${localize_data.text_results_found}`;
				} else {
					total_records_text = `${response.data.total_records} ${localize_data.text_result_found}`;
				}

				$('.listing-results-count').text(total_records_text);

				var isMobileView = window.innerWidth < 768;

				if (Object.keys(filters).length) {
					//new filter was applied
					if (!isMobileView) {
						$appliedFiltersContainer.removeClass('hidden');
					}
				} else {
					//clear filters
					$appliedFiltersContainer.addClass('hidden');
				}
			}
		);
	});

	// handle clear filters
	$('.clear-listing-filters').on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();

		$listingFilter.removeClass('active');

		// Select ALL Filter
		$('.listing-filter[data-filter-value="ALL"]').trigger('click');
	});

	function getAppliedFilters() {
		const filters = {};

		$('.filter-group').each(function () {
			const key = $(this).data('filter-key');
			const activeFilter = $(this).find('li.active');

			const filterValue = activeFilter.data('filter-value');

			if (filterValue && filterValue != 'ALL') {
				filters[key] = filterValue;
			}
		});

		return filters;
	}

	function loadVenues(page, filters, callback) {
		paged = page;

		$.ajax({
			url: ajaxUrl,
			type: 'POST',
			data: {
				action: 'listing_filter',
				paged: paged,
				pageSize: 10,
				filters: filters,
				nonce: nonce,
				cpt: cpt
			},
			success: function (response) {
				callback(null, response);

				if (!response.data.has_more_pages) {
					$loadMoreButton.hide();
				} else {
					$loadMoreButton.show();
				}
				applySliderAnimations();
				addAOSEffects();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				if (jqXHR.responseJSON && jqXHR.responseJSON.data) {
					callback(jqXHR.responseJSON.data);
				} else {
					callback(errorThrown);
				}
			}
		});
	}

	applySliderAnimations();

	// code to open table booking widget
	function handleOpenTableWidget(e, button) {
		e.preventDefault();
		if (button) {
			// Access the data property of the current card
			let cta = button.querySelector('.cta');

			let resto_id = cta.dataset.bookingId;
			// Add your logic here when the button is clicked

			// Initialize the widget configuration

			if (window.stayTableWidget) {
				stayTableWidget?.handleOpenTableWidget(resto_id);
			}
		}
	}

	// Select all cards with the specific data-group-id
	const cards = document.querySelectorAll('[data-group-id="eat-drink"]');
	const cardsNightlife = document.querySelectorAll(
		'[data-group-id="nightlife"]'
	);

	// Loop through each card
	cards.forEach(card => {
		// Find all buttons with the class "buttons-cta-row" inside the current card
		const buttons = card.querySelectorAll('.buttons-cta-row');

		// Loop through each button and add event listener
		buttons.forEach(button => {
			button.addEventListener('click', e =>
				handleOpenTableWidget(e, button)
			);
		});
	});

	// Loop through each card
	cardsNightlife?.forEach(card => {
		// Find all buttons with the class "buttons-cta-row" inside the current card
		const buttons = card.querySelectorAll('.buttons-cta-row');

		// Loop through each button and add event listener
		buttons.forEach(button => {
			button.addEventListener('click', e =>
				handleOpenTableWidget(e, button)
			);
		});
	});
})();
