(() => {
	$('.five-music-video-cards-slider').each(function (i, el) {
		let swiperClass = 'five-music-video-cards-slider-' + i;
		$(this).addClass(swiperClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 16,
			freeMode: {
				enabled: true,
				sticky: true
			},
			breakpoints: {
				768: {
					spaceBetween: 20
				}
			}
		});
	});
})();
