function imagePopupSlider() {
	$('.image-popup-slider').each(function (i, el) {
		let swiperClass = 'image-popup-slider-' + i;
		$(this).addClass(swiperClass);

		// navigation
		let prevClass = 'image-popup-slider-prev-' + i;
		let nextClass = 'image-popup-slider-next-' + i;
		$(this).parent().find('.swiper-button-prev').addClass(prevClass);
		$(this).parent().find('.swiper-button-next').addClass(nextClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 16,
			centerInsufficientSlides: true,
			freeMode: {
				enabled: true,
				sticky: true
			},
			navigation: {
				prevEl: '.' + prevClass,
				nextEl: '.' + nextClass
			},
			breakpoints: {
				768: {
					spaceBetween: 20
				}
			}
		});
	});
}
imagePopupSlider();
