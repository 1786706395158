(() => {
	var loaderContainer = document.getElementById('loader-container');
	if (loaderContainer) {
		var video = document.getElementById('loader-video');

		// Apply styles to the body
		function applyBodyStyles() {
			document.body.style.position = 'fixed';
			document.body.style.top = '0';
			document.body.style.overflow = 'hidden';
		}

		// Remove styles from the body
		function removeBodyStyles() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
		}

		applyBodyStyles();
		video.play(); // Play the video

		video.addEventListener('ended', function () {
			loaderContainer.style.display = 'none';
			removeBodyStyles();
		});
	}
})();
