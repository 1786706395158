(() => {
	$('.tab-award-card-grid-section').each(function (i, el) {
		// content
		let tabContentSliderClass = 'award-tab-content-slider-' + i;
		$(this)
			.find('.award-tab-content-slider')
			.addClass(tabContentSliderClass);

		// tab
		let tabSliderClass = 'award-tab-slider-' + i;
		$(this).find('.award-tab-slider').addClass(tabSliderClass);

		let tabSlider = new Swiper('.' + tabSliderClass, {
			speed: 500,
			slideToClickedSlide: false,
			slidesPerView: 'auto',
			freeMode: {
				enabled: true,
				sticky: true
			}
		});

		// Make the tab fully visible when it is clicked
		tabSlider.on('click', function (event) {
			tabSlider.slideTo(event.clickedIndex);
		});

		let contentSlider = new Swiper('.' + tabContentSliderClass, {
			speed: 500,
			autoHeight: true,
			thumbs: {
				swiper: tabSlider
			}
		});
	});
})();
