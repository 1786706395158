(() => {
	$('.featured-event-slider').each(function (i, el) {
		let swiperClass = 'featured-event-slider-' + i;
		$(this).addClass(swiperClass);

		if ($(this).find('.swiper-slide').length > 1) {
			// navigation
			let prevClass = 'featured-event-button-prev-' + i;
			let nextClass = 'featured-event-button-next-' + i;
			$(this).parent().find('.swiper-button-prev').addClass(prevClass);
			$(this).parent().find('.swiper-button-next').addClass(nextClass);

			new Swiper('.' + swiperClass, {
				slidesPerView: 1,
				loop: true,
				speed: 500,
				watchOverflow: true,
				freeMode: {
					enabled: true,
					sticky: true
				},
				//effect: 'fade',
				navigation: {
					prevEl: '.' + prevClass,
					nextEl: '.' + nextClass
				}
			});
		} else {
			// when there is only one slide
			$('.' + swiperClass)
				.find('.swiper-slide')
				.addClass('swiper-slide-active');
		}
	});
})();
