(() => {
	let paged = 1;
	const $listingContainer = $('.js-stay-listing-section');
	const $listingSection = $listingContainer;
	const $clearFilters = $listingSection.find('.clear-listing-filters');

	const $filterBlock = $listingContainer.find('.filters-block');

	const cpt = $filterBlock.data('cpt');
	const nonce = localize_data.nonce;
	const ajaxUrl = localize_data.ajax_url;

	const $listingFilter = $filterBlock.find('.listing-filter');
	const $appliedFiltersContainer = $('.applied-filters-container');

	const $loadMoreButton = $listingContainer.find('.load-more');
	const $listings = $listingContainer.find('.listings');

	const $topLevelLoadingContainer = $listingContainer.find(
		'.js-listing-container-loader'
	);
	const $showMoreLoadingContainer = $listingContainer.find(
		'.js-load-more-container-loader'
	);
	const $errorContainer = $listingContainer.find('.listing-error-container');
	const $filterCta = $listingContainer.find('.filter-dropdown-toggle');
	const $filterModalOverlay = $listingContainer.find(
		'.filters-modal-overlay'
	);
	const $filterModal = $listingContainer.find('.filters-mobile-modal');

	function addAOSEffects() {
		window.inViewPlugin();
	}

	function applySliderAnimations() {
		$('.js-listing-slider').each(function (i, el) {
			let swiperClass = 'js-listing-slider-' + i;
			$(this).addClass(swiperClass);

			if ($(this).find('.swiper-slide').length > 1) {
				let prevClass = 'js-listing-button-prev-' + i;
				let nextClass = 'js-listing-button-next-' + i;
				$(this)
					.parent()
					.find('.swiper-button-prev')
					.addClass(prevClass);
				$(this)
					.parent()
					.find('.swiper-button-next')
					.addClass(nextClass);

				new Swiper('.' + swiperClass, {
					slidesPerView: 1,
					loop: true,
					speed: 500,
					watchOverflow: true,
					navigation: {
						prevEl: '.' + prevClass,
						nextEl: '.' + nextClass
					}
				});
			} else {
				$('.' + swiperClass)
					.find('.swiper-slide')
					.addClass('swiper-slide-active');
			}
		});
	}

	function toggleListingLoader(showLoader) {
		if (showLoader) {
			$topLevelLoadingContainer.removeClass('hidden');
			$listings.html('');
			$loadMoreButton.hide();
		} else {
			$topLevelLoadingContainer.addClass('hidden');
			$loadMoreButton.show();
		}
	}

	function toggleShowMoreLoader(showLoader, showLoadMoreButton) {
		if (showLoader) {
			$loadMoreButton.hide();
			$showMoreLoadingContainer.removeClass('hidden');
			$showMoreLoadingContainer[0].scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		} else {
			$showMoreLoadingContainer.addClass('hidden');

			if (showLoadMoreButton) {
				$loadMoreButton.show();
			}
		}
	}

	$loadMoreButton.on('click', function (event) {
		const filters = getAppliedFilters();

		toggleShowMoreLoader(true, false);

		loadAccommodations(
			paged + 1,
			filters,
			function handleLoadMoreAPICall(error, response) {
				$listings.append(response.data.html);
				toggleShowMoreLoader(false, response.data.has_more_pages > 0);
			}
		);
	});

	function handleModalVisibility(show) {
		const $defaultBookingWidgetBackground = $(
			'#default-booking-widget-background'
		);

		if (show) {
			$filterModalOverlay.css('display', 'flex');
			$filterModal.css('display', 'block');
			$defaultBookingWidgetBackground.css('display', 'none'); // Hide background
		} else {
			$filterModalOverlay.css('display', 'none');
			$filterModal.css('display', 'none');
			$defaultBookingWidgetBackground.css('display', 'block'); // Show background
		}
	}

	// Filter modal
	$filterCta.on('click', function () {
		$filterModalOverlay.css('display', 'flex');
		handleModalVisibility(true);
		$filterModal.css('display', 'block');
		lenis.stop();
		$filterModalOverlay.addClass('show');
		$filterModal.addClass('show');
	});

	$filterModalOverlay.find('.cross-icon').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		handleModalVisibility(false);
		lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	$filterModalOverlay.find('.modal-actions .btn').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		handleModalVisibility(false);
		lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	function checkScreenSize() {
		if (window.matchMedia('(min-width: 768px)').matches) {
			$filterModal.hide();
		} else {
			$filterModal.show();
		}
	}

	$(window).resize(function () {
		checkScreenSize();
	});

	$listingFilter.on('click', function () {
		$listingSection[0].scrollIntoView({
			behavior: 'instant',
			block: 'start'
		});

		const $this = $(this);

		if ($this.hasClass('active')) {
			return false;
		}

		$errorContainer.addClass('hidden');

		$this.siblings().removeClass('active');

		$this.addClass('active');

		const filterKey = $this.closest('.filter-group').data('filter-key');

		const filter = $this.data('filter-value');

		$('[data-filter-key=' + filterKey + ']')
			.find('.listing-filter')
			.removeClass('active');

		$('.listing-filter[data-filter-value=' + filter + ']').addClass(
			'active'
		);

		const svg = $this.find('.icon-svg');

		if ($this.hasClass('active')) {
			svg.find('path').css('fill', 'white');
		} else {
			svg.find('path').css('fill', 'black');
		}

		const filters = getAppliedFilters();

		paged = 1;
		toggleListingLoader(true);

		loadAccommodations(
			paged,
			filters,
			function handleFilterAPICall(error, response) {
				toggleListingLoader(false);

				if (error) {
					$appliedFiltersContainer.addClass('hidden');
					$listings.html('');
					$loadMoreButton.hide();

					$errorContainer
						.find('.error-message-primary-text')
						.text(error.message);
					$errorContainer.removeClass('hidden');

					return;
				}

				$listings.html(response && response.data && response.data.html);

				let total_records_text;

				if (response.data.total_records > 1) {
					total_records_text = `${response.data.total_records} ${localize_data.text_results_found}`;
				} else {
					total_records_text = `${response.data.total_records} ${localize_data.text_result_found}`;
				}

				$('.listing-results-count').text(total_records_text);

				var isMobileView = window.innerWidth < 768;

				if (Object.keys(filters).length) {
					$appliedFiltersContainer.removeClass('hidden');
					if (!isMobileView) {
						$appliedFiltersContainer.removeClass('hidden');
					}
				} else {
					$appliedFiltersContainer.addClass('hidden');
				}

				toggleClearFilterBlockVisibility();
			}
		);
	});

	$clearFilters.on('click', function (event) {
		$listingFilter.removeClass('active');

		$listingSection[0].scrollIntoView({
			behavior: 'instant',
			block: 'start'
		});

		$('.listing-filter[data-filter-value="ALL"]').trigger('click');

		toggleClearFilterBlockVisibility();
	});

	function getAppliedFilters() {
		const filters = {};

		$('.filter-group').each(function () {
			const key = $(this).data('filter-key');
			const activeFilter = $(this).find('li.active');

			const filterValue = activeFilter.data('filter-value');

			if (filterValue && filterValue != 'ALL') {
				filters[key] = filterValue;
			}
		});

		return filters;
	}

	function loadAccommodations(page, filters, callback) {
		paged = page;

		$.ajax({
			url: ajaxUrl,
			type: 'POST',
			data: {
				action: 'listing_filter',
				paged: paged,
				pageSize: 5,
				filters: filters,
				nonce: nonce,
				cpt: cpt
			},
			success: function (response) {
				callback(null, response);

				if (!response.data.has_more_pages) {
					$loadMoreButton.hide();
				} else {
					$loadMoreButton.show();
				}
				applySliderAnimations();
				addAOSEffects();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				if (jqXHR.responseJSON && jqXHR.responseJSON.data) {
					callback(jqXHR.responseJSON.data);
				} else {
					callback(errorThrown);
				}
			}
		});
	}

	function toggleClearFilterBlockVisibility() {
		const filters = getAppliedFilters();
		const hasFiltersApplied = Object.keys(filters).length > 0;

		if (hasFiltersApplied) {
			$('.clear-filter-block').css('display', 'block');
		} else {
			$('.clear-filter-block').css('display', 'none');
		}
	}

	applySliderAnimations();
	toggleClearFilterBlockVisibility();

	// code to open stay booking widget
	function handleOpenStayWidget(e, button) {
		e.preventDefault();
		if (button) {
			// Access the data property of the current card
			let cta = button.querySelector('.cta');

			let stay_id = cta.dataset.bookingId;
			// Add your logic here when the button is clicked

			// Initialize the widget configuration

			if (window.stayTableWidget) {
				stayTableWidget?.handleCheckAvailability(stay_id);
			}
		}
	}

	// Select all cards with the specific data-group-id
	const cards = document.querySelectorAll('[data-group-id="stays"]');

	// Loop through each card
	cards.forEach(card => {
		// Find all buttons with the class "buttons-cta-row" inside the current card
		const buttons = card.querySelectorAll('.buttons-cta-row');

		// Loop through each button and add event listener
		buttons.forEach(button => {
			button.addEventListener('click', e =>
				handleOpenStayWidget(e, button)
			);
		});
	});
})();
