(() => {
	const sections = document.getElementsByClassName(
		'social-cards-slider-section'
	);

	if (sections.length > 0) {
		Array.from(sections).forEach((section, i) => {
			const slider = section.querySelector('.social-cards-slider');
			if (slider) {
				let swiperClass = 'social-cards-slider-' + i;
				$(slider).addClass(swiperClass);

				let prevClass = 'social-cards-slider-button-prev-' + i;
				let nextClass = 'social-cards-slider-button-next-' + i;

				$(section).find('.swiper-button-prev').addClass(prevClass);
				$(section).find('.swiper-button-next').addClass(nextClass);

				// Initialize Swiper
				new Swiper('.' + swiperClass, {
					speed: 500,
					slidesPerView: 'auto',
					spaceBetween: 16,
					centerInsufficientSlides: true,
					navigation: {
						prevEl: '.' + prevClass,
						nextEl: '.' + nextClass
					},
					breakpoints: {
						768: {
							spaceBetween: 20
						}
					}
				});
			}
		});
	}
})();
