// Magnific Popup - Gallery
////////////////////////////////////////////////
$('.js-popup-slider').magnificPopup({
	delegate: 'a', // child items selector, by clicking on it popup will open
	type: 'image',
	gallery: {
		enabled: true,
		preload: [0, 1]
	},
	mainClass: 'mfp-zoom-in mfp-fade', // add class for animation
	removalDelay: 300, // delay removal by X to allow out-animation
	titleSrc: 'title', // custom function will nest inside image: {}
	callbacks: {
		imageLoadComplete: function () {
			var self = this;
			setTimeout(function () {
				self.wrap.addClass('mfp-image-loaded');
				$('.mfp-close, .mfp-content, .mfp-img').css(
					'cursor',
					'pointer'
				); // Ensure pointer cursor on image and close button
			}, 16);
		},
		close: function () {
			this.wrap.removeClass('mfp-image-loaded');
			$('.js-popup-slider .swiper-slide a').css('cursor', 'pointer'); // Reset cursor to pointer on close
		}
	}
});
