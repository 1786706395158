function scrollNavigationSlider() {
	$('.scroll-navigation-slider').each(function (i, el) {
		let swiperClass = 'scroll-navigation-slider-' + i;
		$(this).addClass(swiperClass);

		let tabSlider = new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 0,
			slideToClickedSlide: false,
			freeMode: {
				enabled: true,
				sticky: true
			}
		});

		// Make the tab fully visible when it is clicked
		tabSlider.on('click', function (event) {
			tabSlider.slideTo(event.clickedIndex);
		});
	});
}
scrollNavigationSlider();
