(() => {
	$('.five-music-artist-slider-section').each(function (i, el) {
		// content
		let tabContentSliderClass = 'five-music-artist-slider-' + i;
		$(this)
			.find('.five-music-artist-slider')
			.addClass(tabContentSliderClass);

		// tab
		let tabSliderClass = 'five-music-artist-title-slider-' + i;
		$(this)
			.find('.five-music-artist-title-slider')
			.addClass(tabSliderClass);

		let tabSlider = new Swiper('.' + tabSliderClass, {
			slidesPerView: 1,
			speed: 500,
			slideToClickedSlide: false,
			direction: 'vertical'
		});

		let contentSlider = new Swiper('.' + tabContentSliderClass, {
			speed: 500,
			control: '.five-music-artist-title-slider-0',
			thumbs: {
				swiper: tabSlider
			}
		});
	});
})();
