(() => {
	// Register the GSAP plugin
	gsap.registerPlugin(ScrollTrigger);

	// Create the timeline for the animations
	const timeline = gsap.timeline();

	// Scale the triangle after a 2-second delay and hide the heading and subheading simultaneously
	timeline
		.to(
			[
				'.text-second-layer .heading',
				'.text-second-layer .sub-heading',
				'.explore-second'
			],
			{
				opacity: 0,
				duration: 0.2 // Fade out quickly
			},
			'+=2' // Delay both animations by 2 seconds
		)
		.to(
			'.triangle',
			{
				scale: 25,
				ease: 'power1.inOut', // Smooth easing
				duration: 1 // Duration of the scaling animation
			},
			'-=0.2'
		); // Start scaling 0.2 seconds before fade-out ends for a smooth transition
})();
