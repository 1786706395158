// Global variables
const headerDropdownToggle = document.querySelector('.header-dropdown-toggle');
const siteHeader = document.querySelector('.site-header');
const headerNav = document.querySelector('.header-nav');
const headerDropdownCenter = document.querySelector('.header-dropdown-center');
const primaryMenuLinks = document.querySelectorAll('.primary-menu > li > a');

// Functions to control #site-content scrolling
let scrollPosition = 0;

function freezeScroll() {
	scrollPosition = window.scrollY;
	document.body.style.position = 'fixed';
	document.body.style.top = `-${scrollPosition}px`;
	document.body.style.width = '100%'; // Ensure the body doesn't shift horizontally
}

function unfreezeScroll() {
	document.body.style.position = '';
	document.body.style.top = '';
	document.body.style.width = ''; // Reset any width adjustments
	setTimeout(() => {
		window.scrollTo({ top: scrollPosition, behavior: 'instant' });
	}, 0); // Add a 100ms delay
}

(() => {
	const musicIcon = document.querySelector('.music-icon');
	const resumeIcon = document.querySelector('.resume-icon');
	const audioPlayer = document.getElementById('audio-player');

	function toggleAudio() {
		if (audioPlayer.paused) {
			audioPlayer.play();
			musicIcon.style.display = 'none';
			resumeIcon.style.display = 'block';
		} else {
			audioPlayer.pause();
			musicIcon.style.display = 'block';
			resumeIcon.style.display = 'none';
		}
	}

	if (musicIcon && resumeIcon && audioPlayer) {
		musicIcon.addEventListener('click', toggleAudio);
		resumeIcon.addEventListener('click', toggleAudio);
	}

	if (
		headerDropdownToggle &&
		headerNav &&
		headerDropdownCenter &&
		primaryMenuLinks.length > 0
	) {
		headerDropdownToggle.addEventListener('click', () => {
			headerNav.classList.toggle('js-inactive');

			// Remove js-active class from all the menu items
			primaryMenuLinks.forEach(link => {
				link.classList.remove('js-active');
			});

			headerDropdownCenter.classList.remove('js-active');

			// Freeze scroll when dropdown is opened
			if (headerNav.classList.contains('js-inactive')) {
				freezeScroll();
			} else {
				unfreezeScroll();
			}
		});

		// Show headerDropdownCenter when any primaryMenuLink is clicked
		primaryMenuLinks.forEach(link => {
			if (link.hasAttribute('data-toggle-click')) {
				link.addEventListener('click', () => {
					headerDropdownCenter.classList.add('js-active');
					freezeScroll(); // Freeze scroll when submenu is opened
				});
			}
		});
	}

	if (headerDropdownCenter) {
		// Remove js-active from all the submenu and its container
		headerDropdownCenter.querySelectorAll('.back-link').forEach(link => {
			link.addEventListener('click', e => {
				e.preventDefault();

				headerDropdownCenter.classList.remove('js-active');
				headerDropdownCenter
					.querySelectorAll('.sub-menu-block')
					.forEach(subMenu => {
						subMenu.classList.remove('js-active');
					});

				primaryMenuLinks.forEach(link => {
					link.classList.remove('js-active');
				});

				unfreezeScroll(); // Unfreeze scroll when submenu is closed
			});
		});
	}
})();
