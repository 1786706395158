(function () {
	const profileBlock = document.querySelector('.profile-block');
	if (profileBlock) {
		const toggleButton = profileBlock.querySelector('.show-hide-btn');
		const block2 = profileBlock.querySelector('.block2');

		toggleButton.addEventListener('click', function () {
			if (block2.classList.contains('hidden')) {
				block2.classList.remove('hidden');
				toggleButton.textContent = 'Show less';
			} else {
				block2.classList.add('hidden');
				toggleButton.textContent = 'Show more';
			}
		});
	}
})();
