(() => {
	$('.logo-slider').each(function (i, el) {
		let swiperClass = 'logo-slider-' + i;
		$(this).addClass(swiperClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 40,
			centerInsufficientSlides: true,
			freeMode: {
				enabled: true,
				sticky: true
			},
			breakpoints: {
				992: {
					spaceBetween: 80
				}
			}
		});
	});
})();
