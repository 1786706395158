letterCarousel('.scrolling-text', '.ticker-block');
function letterCarousel(parent_cls, child_cls) {
	let elements = jQuery(parent_cls + ' ' + child_cls);
	// Iterate over each element set
	elements.each(function () {
		let e = jQuery(this), // Current element
			t = jQuery(window).height();
		jQuery(window).on('scroll', function () {
			if (jQuery(parent_cls).length) {
				let t = jQuery(document).scrollTop() + jQuery(window).height(),
					n = jQuery(parent_cls).offset().top;

				if (n <= t) {
					let i =
						jQuery(document).scrollTop() -
						n +
						jQuery(window).height();
					let scroll = i - 1200;
					let scroll_slow = scroll + scroll / 70 / 100;
					let img_scroll = (scroll_slow * 30) / 100;

					// Check if the rightToLeft class is present
					let direction = e
						.closest(parent_cls)
						.hasClass('rightToLeft')
						? -img_scroll - 500
						: img_scroll;

					e.css({
						transform: 'translateX(' + direction + 'px)'
					});
				}
			}
		});
	});
}
