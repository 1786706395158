(() => {
	let paged = 1;
	const nonce = localize_data.nonce;
	const ajaxUrl = localize_data.ajax_url;

	const $eventListingSection = $('.event-listing-section');
	const $filterBlock = $eventListingSection.find('.filters-block');
	const $listingSection = $('#event-listing-section');
	const $clearFilters = $listingSection.find('.clear-listing-filters');

	const $listingFilter = $filterBlock.find('.listing-filter');
	const $metaFilter = $filterBlock.find('.meta-filter');
	const $appliedFiltersContainer = $filterBlock.find(
		'.applied-filters-container'
	);
	const $loadMoreButton = $eventListingSection.find('.load-more');
	const $listings = $eventListingSection.find('.listings');
	const $topLevelLoadingContainer = $eventListingSection.find(
		'.js-listing-container-loader'
	);
	const $showMoreLoadingContainer = $eventListingSection.find(
		'.js-load-more-container-loader'
	);
	const $errorContainer = $eventListingSection.find(
		'.listing-error-container'
	);
	const $filterCta = $eventListingSection.find('.filter-dropdown-toggle');
	const $filterModalOverlay = $eventListingSection.find(
		'.filters-modal-overlay'
	);
	const $filterModal = $eventListingSection.find('.filters-mobile-modal');

	function addAOSEffects() {
		window.inViewPlugin();
	}

	function toggleListingLoader(showLoader) {
		if (showLoader) {
			$topLevelLoadingContainer.removeClass('hidden');
			$listings.html('');
			$loadMoreButton.hide();
		} else {
			$topLevelLoadingContainer.addClass('hidden');
			$loadMoreButton.show();
		}
	}

	function toggleShowMoreLoader(showLoader, showLoadMoreButton) {
		if (showLoader) {
			$loadMoreButton.hide();
			$showMoreLoadingContainer.removeClass('hidden');
			$showMoreLoadingContainer[0].scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		} else {
			$showMoreLoadingContainer.addClass('hidden');
			if (showLoadMoreButton) {
				$loadMoreButton.show();
			}
		}
	}

	function handleModalVisibility(show) {
		const $defaultBookingWidgetBackground = $(
			'#default-booking-widget-background'
		);

		if (show) {
			$filterModalOverlay.css('display', 'flex');
			$filterModal.css('display', 'block');
			$defaultBookingWidgetBackground.css('display', 'none'); // Hide background
		} else {
			$filterModalOverlay.css('display', 'none');
			$filterModal.css('display', 'none');
			$defaultBookingWidgetBackground.css('display', 'block'); // Show background
		}
	}

	// filter modal
	$filterCta.on('click', function () {
		$filterModalOverlay.css('display', 'flex');
		handleModalVisibility(true);
		$filterModal.css('display', 'block');
		// lenis.stop();
		$filterModalOverlay.addClass('show');
		$filterModal.addClass('show');
	});

	$filterModalOverlay.find('.cross-icon').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		handleModalVisibility(false);
		// lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	$filterModalOverlay.find('.modal-actions .btn').on('click', function () {
		$filterModalOverlay.css('display', 'none');
		// lenis.start();
		$filterModalOverlay.removeClass('show');
		$filterModal.removeClass('show');
	});

	function checkScreenSize() {
		if (window.matchMedia('(min-width: 768px)').matches) {
			$filterModal.hide();
		} else {
			$filterModal.show();
		}
	}

	// Check screen size on window resize
	$(window).resize(function () {
		checkScreenSize();
	});

	// Handle filter selection
	$listingFilter.on('click', function (event) {
		$listingSection[0].scrollIntoView({
			behavior: 'instant',
			block: 'start'
		});

		const $this = $(this);

		// if ($this.hasClass('active')) {
		// 	return false;
		// }

		$errorContainer.addClass('hidden');
		$this.siblings().removeClass('active');
		$this.addClass('active');

		const filter = $this.data('filter-value');
		$('.listing-filter[data-filter-value=' + filter + ']').addClass(
			'active'
		);

		const filters = getAppliedFilters();
		const meta = getMetaFilterValue();

		paged = 1; // Reset to the first page
		toggleListingLoader(true);

		loadEvents(
			paged,
			filters,
			meta,
			function handleFilterAPICall(error, response) {
				toggleListingLoader(false);

				if (error) {
					// display the error message on the UI
					$appliedFiltersContainer.addClass('hidden');

					$listings.html('');
					$loadMoreButton.hide();

					$errorContainer
						.find('.error-message-primary-text')
						.text(error.message);
					$errorContainer.removeClass('hidden');

					return;
				}

				$listings.html(response && response.data && response.data.html);

				let total_records_text;

				if (response.data.total_records > 1) {
					total_records_text = `${response.data.total_records} events ${localize_data.text_results_found}`;
				} else {
					total_records_text = `${response.data.total_records} event ${localize_data.text_result_found}`;
				}

				$('.listing-results-count').text(total_records_text);

				var isMobileView = window.innerWidth < 768;

				if (Object.keys(filters).length) {
					//new filter was applied
					if (!isMobileView) {
						$appliedFiltersContainer.removeClass('hidden');
					}
				} else {
					//clear filters
					$appliedFiltersContainer.addClass('hidden');
				}
			}
		);
	});

	// Handle Meta filter selection
	$metaFilter.on('click', function () {
		$listingSection[0].scrollIntoView({
			behavior: 'instant',
			block: 'start'
		});

		const $this = $(this);

		if ($this.hasClass('active')) {
			return false;
		}

		$errorContainer.addClass('hidden');
		$metaFilter.removeClass('active');
		$this.addClass('active');

		const meta = getMetaFilterValue();
		const filters = getAppliedFilters();

		const filterValue = $this.data('filter-value');
		$('.meta-filter[data-filter-value=' + filterValue + ']').addClass(
			'active'
		);

		paged = 1; // Reset to the first page
		toggleListingLoader(true);

		loadEvents(
			paged,
			filters,
			// meta,
			filterValue,
			function handleFilterAPICall(error, response) {
				toggleListingLoader(false);

				if (error) {
					// display the error message on the UI
					$appliedFiltersContainer.addClass('hidden');

					$listings.html('');
					$loadMoreButton.hide();

					$errorContainer
						.find('.error-message-primary-text')
						.text(error.message);
					$errorContainer.removeClass('hidden');

					return;
				}

				$listings.html(response && response.data && response.data.html);

				let total_records_text;

				if (response.data.total_records > 1) {
					total_records_text = `${response.data.total_records} events ${localize_data.text_results_found}`;
				} else {
					total_records_text = `${response.data.total_records} event ${localize_data.text_result_found}`;
				}

				$('.listing-results-count').text(total_records_text);

				var isMobileView = window.innerWidth < 768;

				if (Object.keys(filters).length) {
					//new filter was applied
					if (!isMobileView) {
						$appliedFiltersContainer.removeClass('hidden');
					}
				} else {
					//clear filters
					$appliedFiltersContainer.addClass('hidden');
				}
			}
		);
	});

	// Handle clear filters
	$clearFilters.on('click', function (event) {
		$listingSection[0].scrollIntoView({
			behavior: 'instant',
			block: 'start'
		});

		$listingFilter.removeClass('active');
		$metaFilter.removeClass('active');

		$('.listing-filter[data-filter-value="all-venues"]').addClass('active');
		$('.listing-filter[data-filter-value="all-slots"]').addClass('active');
		$('.meta-filter[data-filter-value="all"]').addClass('active');

		$(
			'.event-timing-select .listing-filter[data-filter-value="all-slots"]'
		)[0].click();

		$(
			'.venue-select .listing-filter[data-filter-value="all-venues"]'
		)[0].click();
	});

	function getMetaFilterValue() {
		const metaFilterValue = $('.meta-filter.active')[0].dataset.filterValue;
		let meta;

		if (metaFilterValue != 'all') {
			meta = metaFilterValue;
		}

		return meta;
	}

	function getAppliedFilters() {
		const filters = {};

		$('.select-group').each(function () {
			const key = $(this).data('filter-key');
			const activeFilter = $(this).find('li.active');
			const filterValue = activeFilter.data('filter-value');

			if (activeFilter) {
				$('.clear-filter-block').css('display', 'block');
			} else {
				$('.clear-filter-block').css('display', 'none');
			}

			if (
				filterValue &&
				(filterValue !== 'al-slots' || filterValue !== 'all-venues')
			) {
				if (filterValue === 'all-venue') {
					filters[key] = 'all';
				} else {
					filters[key] = filterValue;
				}
			}
		});

		return filters;
	}

	$loadMoreButton.on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();

		const filters = getAppliedFilters();
		const meta = getMetaFilterValue();

		toggleShowMoreLoader(true, false);

		loadEvents(
			paged + 1,
			filters,
			meta,
			function handleLoadMoreAPICall(error, response) {
				if (error) {
					$errorContainer
						.removeClass('hidden')
						.find('.error-message')
						.text(error);
					return;
				}
				$listings.append(response.data.html);
				toggleShowMoreLoader(false, response.data.has_more_pages > 0);
			}
		);
	});

	function loadEvents(page, filters, meta, callback) {
		paged = page;

		$.ajax({
			url: ajaxUrl,
			type: 'POST',
			data: {
				action: 'event_listing_filter',
				paged: paged,
				pageSize: 9,
				filters: filters,
				meta: meta,
				nonce: nonce
			},
			success: function (response) {
				callback(null, response);

				// Re-run updateHeight to account for new elements
				setTimeout(() => {
					updateHeight();
				}, 100);

				if (!response.data.has_more_pages) {
					$loadMoreButton.hide();
				} else {
					$loadMoreButton.show();
				}
				addAOSEffects();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				if (jqXHR.responseJSON && jqXHR.responseJSON.data) {
					callback(jqXHR.responseJSON.data);
				} else {
					callback(errorThrown);
				}
			}
		});
	}

	// Toggle dropdown visibility
	$('.select-selected').click(function (e) {
		e.stopPropagation();
		const $dropdown = $(this).siblings('.select-items');
		$('.select-items').addClass('hidden'); // Close all dropdowns
		$dropdown.toggleClass('hidden'); // Toggle the current dropdown
	});

	// Handle item selection
	$('.select-items li').click(function () {
		const selectedDropdownType = $(this)
			.closest('.custom-select')
			.find('.select-selected')
			.data('type');

		const selectedText = $(this).text();
		const selectedValue = $(this).data('filter-value');
		const $selectSelected = $(this)
			.closest('.custom-select')
			.find('.select-selected');

		$(
			'.select-selected[data-type=' +
				selectedDropdownType +
				']:has(+ .select-items [data-filter-value=' +
				selectedValue +
				'])'
		).html(selectedText + ' <span class="caret"></span>');

		$(this).closest('.select-items').addClass('hidden');
	});

	// Close dropdown if clicking outside
	$(document).click(function () {
		$('.select-items').addClass('hidden');
	});
})();
