(() => {
	$('.offer-card-slider').each(function (i, el) {
		let swiperClass = 'offer-card-slider-' + i;
		$(this).addClass(swiperClass);

		new Swiper('.' + swiperClass, {
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 16,
			centerInsufficientSlides: true,
			freeMode: {
				enabled: true,
				sticky: true
			},
			breakpoints: {
				768: {
					spaceBetween: 20
				}
			},
			breakpoints: {
				992: {
					slidesPerView: 2
				}
			}
		});
	});
})();
