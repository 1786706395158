(() => {
	const liveTime = document.getElementById('live-time');

	function time() {
		const options = {
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true
		};
		liveTime.textContent = new Date().toLocaleTimeString('en-US', options);
	}

	setInterval(time, 1000);
})();
